.about-container {
  display: flex;
  flex-direction: column;
  max-width: 50vw;
  min-height: 20vh;
  margin-top: 10vh;
}

.about-section {
  display: flex;
  font-size: 30px;
  padding: 25px 0px;
  border-bottom: 0.2rem solid #264143;
}

.about-header {
  display: flex;
  margin: 2px;
  border-bottom: 0.2rem solid #264143;

}

.photo-me {
  float: left;
  margin-bottom: 10px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);

}

.details {
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  line-height: 2vw;
  font-size: 1rem;
  margin-right: 1rem; 
  margin-top: 30px; 

}

.reach-me {
  display: flex;
  justify-content: center;
  font-family: 'Righteous', cursive;
  color: #de5499;
  text-align: center;
  font-weight: 400;
  font-size: 0.4em;
}

li {
  margin: 3px;
}

.tech-stack {
  font-family: "Righteous", cursive;
  font-size: 1.1rem;
  margin-top: 1rem;

}

.tech-stack img {
  height: 2em;
  margin-right: 5px;

}

.tech-stack img:hover {
  background-color: hsl(13, 11%, 85%);
  padding: 0.2rem;
  border-radius: 5px;
  margin: -4px 2px -3px -3px;
}


.right-details {
  display: flex;
  flex-direction: column;
  padding-top:  30px;
  padding-left: 30px;
  border-radius: 2px;
  
}

.details-accents {
color: #de5499;
letter-spacing: 1.5px;
font-style: italic;

}

.about-intro {
  margin-top: 0px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: 900;
  
  
}

@media screen and (max-width: 1200px){
  .about-container {
    max-width: 60vw;
    padding: 4vw;
  }

  .details {
    font-size: 0.8rem;
    line-height: 2em;
    padding: 1em;

  }

  .tech-stack {
    font-size: 0.5em;
  }

  .tech-stack img {
    height: 2.5em;
  }

  .photo-me {
    max-width: 7em;
    max-height: 7em;

  }
  .right-details {
    padding-top: 1em;
    padding-left: 1em;
  }
  .about-header {
    font-size: 6vw;
    margin-top: 20vw;
  }
   }

   @media screen and (min-width: 2000px) {
     .details {

       line-height: 52px;
     }
     
   }

   @media screen and (max-width: 900px){
    .about-section {
      flex-direction: column ;
    }
  
   }

