.name {
  color: #92dae6;
  animation: change-color 5s infinite;
}

@keyframes change-color {
  0% {
    color: #92dae6;
  }
  25% {
    color: #4f777b;
  }
  50% {
    color: #e99f4c;
  }
  75% {
    color: #ff8beb;
  }
}

.greeting {
  font-size: 100px;
  font-weight: bold;
  margin-bottom: 0px;
}

.intro {
  margin-top: 0px;
  font-size: 40px;
}

@media screen and (max-width: 1200px) {
  .greeting {
    font-size: 8vw;
  }

  .intro {
    font-size: 3.4vw;
  }

  h1 {
    font-size: 6vw;
  }
}
