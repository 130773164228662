.contact-section {
  display: flex;
}

.contact-left-side {
  margin-right: 1em;
}
.contact-form {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  margin-right: 30px;
}

.contact-left-side {
  margin-right: 30px;
}
.contact-container {
  display: flex;
  flex-direction: row;
  width: 60vw;
}

.contact-form input {
  background-color: #f2ebe9;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: "Lato", sans-serif;
  font-size: 20px;
  margin-top: 20px;
}

.contact-form input:focus {
  outline: none;
  background-color: #e2ccc5;
}
.contact-form input:hover {
  background-color: #e2ccc5;

}

.header-socials {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: 3px;
}

.contact-form textarea:focus {
  outline: none;
  background-color: #e2ccc5;
}

.contact-intro {
  font-family: "Lato", sans-serif;
  font-size: 1.4rem;
  color: #de5499;
  font-style: italic;
  font-weight: 400;
  margin: 0;
}

.linkedin-link {
  font-family: "Lato", sans-serif;
  font-size: 1.3rem;
  all: unset;
}

.lets-chat {
  margin-bottom: 10px;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 1.5em;
}

.contact-form textarea {
  background-color: #f2ebe9;
  border: 0;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 20px;
  padding: 10px 20px;
  font-family: "Lato", sans-serif;
  border: none;
  resize: none;
}

.contact-form textarea:hover {
  background-color: #e2ccc5;
}

.contact-form button {
  background-color: #de5499;
  color: #f2ebe9;
  align-self: center;
  border: 0;
  padding: 10px 20px;
  font-family: "Righteous", cursive;
  font-weight: 600;
  font-size: 20px;
  border-radius: 10px;
  margin-top: 25px;
}

.contact-form button:hover {
  background-color: #e99f4c;
}

.contact-form p {
  text-align: center;
  font-family: "Lato", sans-serif;
  color: #de5499;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .contact-form input {
    font-size: 1.8vw;
  }

  .contact-form textarea {
    font-size: 1.8vw;
  }

  .contact-form label {
    font-size: 2.4vw;
  }

  .contact-form button {
    font-size: 2vw;
  }

  .lets-chat {
    font-size: 2.4vw;
  }

  .contact-intro {
    font-size: 1.8vw;
  }

  .linkedin-link {
    font-size: 1.8vw;
  }
  .header-socials {
    font-size: 2vw;
  }
  .contact-container {
    flex-direction: column;
    margin-right: 0;
    width: 60vw;
  }

  .contact-left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
