.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90rem;
  margin-top: 10vh;
}

.item {
  background-color: #f2ebe9;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0.1 0.125rem 0.3rem 0.1 rgb(0 0 0 / 11%);
}

.item:hover {
  box-shadow: 0 0.2rem 0.25rem 0 rgb(0 0 0 / 11%);

}

.image-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.image-container img {
  max-width: 400px;
}

.item a {
  text-decoration: none;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  display: flex;
  justify-content: center;
  color: #e99f4c;
}

.project-tech-stack {
  display: flex;
  justify-content: center;
  width: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  flex-wrap: wrap;
}

.stack-item {
  display: flex;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  border: 1.5px solid black;
  padding: 4px;
}

.item p {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 1.2rem;
}

.item h1 {
  margin: 10px 0px;
  color: #de5499;
  font-size: 2rem;
}
.item a:hover {
  color: #de5499;
}

@media screen and (max-width: 2100px) {
  .projects-container {
    width: 60rem;
  }
}

@media screen and (max-width: 1500px) {
  .projects-container {
    width: 30rem;
  }
}

@media screen and (max-width: 750px) {
  .projects-container {
    width: 50vw;
    margin-top: 20vw;
  }

  .item {
    width: 50%;
    margin-left: 65px;
  }

  .image-container img {
    width: 13rem;
  }

  .item h1 {
    font-size: 1.2rem;
  }

  .item p {
    font-size: 1rem;
  }

  .stack-item {
    font-size: 0.7rem;
  }
}
