@import url("https://fonts.googleapis.com/css?family=Roboto:300,400");

body {
  background-color: #eddcd9;
  font-family: "Righteous", cursive;
  overflow-x: hidden;
  overflow-y: scroll;
}

.content {
  font-family: "Righteous", cursive;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.rectangle1 {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -60%;
  right: 0;
  width: 1600px;
  height: 600vh;
  background-color: #eddcd9;
  opacity: 1;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #de5499;
}

.rectangle2 {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -60%;
  right: 0;
  width: 1500px;
  height: 600vh;
  background-color: #eddcd9;
  opacity: 1;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #4f777b;
}
.rectangle3 {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -60%;
  right: 0;
  width: 1500px;
  height: 600vh;
  background-color: #eddcd9;
  opacity: 1;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #e99f4c;
}
.rectangle4 {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -60%;
  right: 0;
  width: 1700px;
  height: 600vh;
  background-color: #eddcd9;
  opacity: 1;
  border-left-style: solid;
  border-left-width: 5px;
  border-left-color: #ff8beb;
}
.rectangle5 {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -60%;
  right: 0;
  width: 1000vw;
  height: 600vh;
  flex-grow: 1;
  background-color: #eddcd9;
  opacity: 1;
  border-left-style: solid;
  border-left-width: 6px;
  border-left-color: #92dae6;
}

section {
  display: grid;
  place-items: center;
  min-height: 100vh;
  font-weight: 100;
  margin-top: 0;
}

.nav {
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: -5px;
  z-index: 2;
  height: 70px;
  min-height: 70px;
  width: calc(100% - 1.5rem);
  background-color: #f2ebe9;
  padding: 0 1rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
  font-family: "Righteous", cursive;
  color: #de5499;

}

.nav__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.nav img {
  width: 160px;
  animation: nav-load 500ms ease-in;
}

.nav__container__actions {
  display: flex;
  justify-content: flex-end;
  display: inline-flex;
  flex-flow: row nowrap;
  padding-left: 50px;
  flex: 1;
  animation: nav-load-links 500ms ease-in;
}

.active {
  color: #e99f4c;
}

ul {
  display: flex;
  font-size: 0.9rem;
  list-style: none;
  padding: 0;
}

li {
  cursor: pointer;
}

li:hover {
  text-decoration: underline;
}

.content {
  color: #264143;
}

.website-links {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
}

.nav__container__actions ul {
  gap: 1rem;
}

.copyright {
  display: flex;
 justify-content: center;
 font-size: 0.7rem;
 font-weight: 200;

}

a {
  font-size: 1.5em;
}

h1 {
  text-align: center;
  font-size: 4vw;
  margin-bottom: 10px;
  margin-top: 0px;
}

.social-svg:hover {
  color: #e99f4c;
}

@media screen and (max-width: 768px) {
  a {
    font-size: 2.7vw;
  }
  .nav img {
    width: 20vw;
  }

  .copyright {
    font-size: 2.7vw;
  }
}

@media screen and (max-width: 1500px) {
  .rectangle1 {
    height: 1000vh;
  }

  .rectangle2 {
    height: 1000vh;
  }
  .rectangle3 {
    height: 1000vh;
  }
  .rectangle4 {
    height: 1000vh;
  }
  .rectangle5 {
    height: 1000vh;
  }
}

@keyframes nav-load {
  0% {
    transform: translateX(-200%);

  }
  
  100% {
    transform: translateX(0%);
  }
}

@keyframes nav-load-links {
  0% {
    transform: translateX(100%);

  }
  
  100% {
    transform: translateX(0%);
  }
}